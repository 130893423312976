import React, { Component } from "react";
import { Typography, Divider, Row, Col, Button, Icon } from "antd";
// const { Header, Footer, Sider, Content } = Layout;
// import HeroImg from "../hero.png";
import HeroImg from "../hero2.png";
import {content} from '../content'

export default class Hero extends Component {
  state = {
    hovered: null
  };
  render() {
    const {hero} = content
    return (
      <div
        style={hero.sectionStyle}
      >
        <Row type="flex" justify="center">
          <Col xs={{span: 22}} lg={{span: 24}}>
            <Typography.Title level={hero.title.level} style={{fontWeight: '800'}}>{hero.title.text}</Typography.Title>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Typography.Title type="secondary" level={hero.subtitle.level}>
              {hero.subtitle.text}
            </Typography.Title>
          </Col>
        </Row>
        {/* <Row type="flex" justify="center">
          <Col span={10}>
            <Divider />
          </Col>
          </Row>
          <Row type="flex" justify="center">
          {hero.watch.map((vid, index) => {
            return (
          <React.Fragment key={index}>
          <Col
          style={{
          marginRight: "1.875rem",
          color: "#00B466"
          }}
          >
          <span
          style={{
          opacity: this.state.hovered === index ? "0.5" : "1",
          cursor: 'pointer'
          }}
          onMouseEnter={() => this.setState({ hovered: index })}
          >
          <Icon
          type="play-circle"
          style={{
          marginRight: "0.25rem"
          }}
          />

          {vid.name}
          </span>
          </Col>
          </React.Fragment>
            );
          })}
        </Row> */}
        <Row justify="center">
          <Button size="large" style={hero.ctaStyle} href="/#contact">Request A Demo</Button>
        </Row>
        <Row justify="center" className="sth">
          <img
            src={HeroImg}
            style={hero.heroImgStyle}
          />
        </Row>

      </div>
    );
  }
}
