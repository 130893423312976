import React, { Component } from "react";
import { Row, Col, Divider, Icon } from "antd";
import drLogo from "../dr_logo.png";
import { HashLink as Link } from "react-router-hash-link";
const footer = {
  col1: [
    {
      name: "Existing Problems",
      link: "#problems"
    },
    {
      name: "How we fix",
      link: "#fix"
    },
    {
      name: "What doctors say",
      link: "#testimonials"
    }
  ],
  col2: [
    {
      name: "Pricing",
      link: "#pricing"
    },
    {
      name: "Contact us",
      link: "#contact"
    }
  ],
  col3: [
    {
      name: "About us",
      link: "/about#about"
    },
    {
      name: "Team",
      link: "/about#team"
    }
  ],
  social: [
    {
      name: "linkedin",
      link: "https://www.linkedin.com/company/slashdr",
      icon: "linkedin",
      theme: "filled"
    },
    {
      name: "facebook",
      link: "https://www.facebook.com/getslashdr",
      icon: "facebook",
      theme: "filled"
    },
    {
      name: "twitter",
      link: "https://twitter.com/slashdr",
      icon: "twitter",
      theme: "outlined"
    }
  ]
};
const iconStyle = {
  backgroundColor: "#fff",
  boxShadow: "0px 14px 24px rgba(0, 0, 0, 0.1)",
  borderRadius: "50%",
  padding: "0.75rem"
};
export default class FooterSection extends Component {
  render() {
    return (
      <div
        style={{
          paddingTop: "4.625rem"
        }}
        className="footerLinks"
      >
        <Row gutter={16} type="flex" justify="center">
          <Col xs={{ span: 0 }} lg={{ span: 5 }}>
            <img src={drLogo} width="20%" />
          </Col>
          <Col xs={{ span: 8 }} lg={{ span: 5 }}>
            <ul>
              {footer.col1.map((item, index) => {
                return (
                  <li
                    style={{
                      textAlign: "left",
                      color: '#999999'
                    }}
                  >
                    <Link
                      to={
                        this.props.location.pathname === "/"
                          ? `${item.link}`
                          : `/${item.link}`
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={{ span: 8 }} lg={{ span: 5 }}>
            <ul>
              {footer.col2.map((item, index) => {
                return (
                  <li
                    style={{
                      textAlign: "left",
                      color: '#999999'
                    }}
                  >
                    <Link
                      to={
                        this.props.location.pathname === "/"
                          ? `${item.link}`
                          : `/${item.link}`
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={{ span: 8 }} lg={{ span: 5 }}>
            <ul>
              {footer.col3.map((item, index) => {
                return (
                  <li
                    style={{
                      textAlign: "left",
                      color: '#999999'
                    }}
                  >
                    <Link
                      to={
                        this.props.location.pathname === "/"
                          ? `${item.link}`
                          : `/${item.link}`
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={{ span: 10 }} lg={{ span: 4 }}>
            <Row type="flex" gutter={16}>
              {footer.social.map((item, index) => {
                return (
                  <Col
                    style={{
                      textAlign: "left"
                    }}
                  >
                    <a href={item.link}>
                      <Icon
                        type={item.icon}
                        theme={item.theme}
                        style={iconStyle}
                      />
                    </a>

                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row gutter={16} type="flex" justify="center">
          <Col span={18}>
            <Divider />
          </Col>
        </Row>
        <Row gutter={16} type="flex" justify="center">
          <Col xs={{ span: 12 }} lg={{ span: 12 }}>
            <span>© 2020 SlashDr.</span>
          </Col>
          <Col xs={{ span: 0 }} lg={{ span: 0 }}>
            <span>cureios.com</span>
            <span style={{marginRight: '1rem'}}>cleansight.ai</span>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <span style={{marginRight: '1rem'}}>Terms Of services</span>
            <span>Privacy Policy</span>
          </Col>
        </Row>
      </div>
    );
  }
}
