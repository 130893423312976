import React, { Component } from "react";
import { Layout, Menu } from "antd";
import Navigation from "./sections/navigation";
import Hero from "./sections/hero";
import Problems from "./sections/problems";
import TheFix from "./sections/thefix";
import Testimonials from "./sections/testimonials";
import Pricing from "./sections/pricing";
import Contact from "./sections/contact";
import FooterSection from "./sections/footer";
const { Header, Footer, Sider, Content } = Layout;

export default class Landing extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout
          style={{
            backgroundColor: "#F4F8FF"
          }}
        >
          <Header
            style={{
              backgroundColor: "#F4F8FF",

              position: "fixed",
              zIndex: 1,
              width: "100%"
            }}
          >
            <Navigation {...this.props} />
          </Header>
          <Content>
            <Hero {...this.props} />
            <Problems {...this.props} />
            <TheFix {...this.props} />
            <Testimonials {...this.props} />
            <Pricing {...this.props} />
            <Contact {...this.props} />
          </Content>
          <Footer className="custom--footer">
            <FooterSection {...this.props} />
          </Footer>
        </Layout>
      </React.Fragment>
    );
  }
}
