import React, { Component } from "react";
import { Divider, Row, Col, Typography, Card, Button } from "antd";

export default class Pricing extends Component {
  render() {
    return (
      <div
        id="pricing"
        style={{
          backgroundColor: "#F4F8FF",
          paddingTop: "5.625rem"
        }}
      >
        <Row type="flex" justify="center" style={{marginBottom: '1.5rem'}}>
          <Col
            xs={{ span: 24 }}
            lg={{
              span: 10
            }}
          >
            <Typography.Title level={2}>
              The first three months are on us!
            </Typography.Title>
            {/* <Typography.Paragraph>
              For three months, you can have the complete experience of SlashDr
              — ERP-class software, concierge-level implementation services, and
              stellar after sales support. Or have your money back!
            </Typography.Paragraph> */}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col
            xs={{ span: 18 }}
            lg={{
              span: 12
            }}
          >
            <Card
              // style={{ width: 600 }}
              className="card--pricing"
              bordered={false}
            >
              <Typography.Title level={3}>
                SlashDr costs ₹ 25,000 per doctor, per year.
              </Typography.Title>

              <Typography.Paragraph type="secondary">
                SlashDr is free for first three months! Or your money back
              </Typography.Paragraph>
              <Divider />
              <Typography.Paragraph style={{marginBottom: '0rem'}}>
                If you want more than 4 licenses, talk to us for special
                pricing.
              </Typography.Paragraph>
            </Card>
            <Typography.Paragraph
              strong
              style={{
                marginTop: "1.875rem"
              }}
            >
              Software, concierge-level implementation services, <br/> and stellar
              after sales support are included.
            </Typography.Paragraph>
            <Row justify="center">
              <Button
                size="large"
                style={{
                  backgroundColor: "#00B466",
                  color: "#fff",
                  marginTop: "1.875rem",
                  border: "0px"
                }}
                href="/#contact"
              >
                Get SlashDr
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
