import React, { Component } from "react";
import { Form, Input, Checkbox, Button, Row, Col, notification } from "antd";

class ContactForm extends Component {
  onSubmit = event => {
    event.preventDefault();
    console.log("hey");
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        const {name, email, phone, canContact} = values
        this.sendDocInfo(name, email, phone, canContact)
      }
    });
  };
  sendDocInfo = (name, email, phone, pref) => {
    let docInfo = {
      fields: {
        name: name,
        email: email,
        phone: phone,
        pref: `${pref}`
      }
    }
    fetch("https://api.airtable.com/v0/appMhbkREnX8ptZwn/info", {
      method: "POST", // or 'PUT'
      body: JSON.stringify(docInfo), // data can be `string` or {object}!
      headers: {
        "Authorization": "Bearer key4dSD1IIiuwoeoW",
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(response => {
        // console.log("Success:", JSON.stringify(response))
        if (response.hasOwnProperty('id')) {
          this.props.form.resetFields()
          notification["success"]({
            message: `Thanks for reaching out.`,
            description: `We will get back to you shortly`
          });
        } else {
          console.log('something went wrong');
          notification["error"]({
            message: "Error",
            description: `Something went wrong. Please try again.`
          });
        }
      })
      .catch(error => console.error("Error:", error));
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={{ span: 20 }} lg={{ span: 8 }}>
              <Form.Item>
                {getFieldDecorator("name", {
                  rules: [
                  {
                      required: true,
                      message: "Please input your name"
                  }
                  ]
                })(
                  <Input
                    placeholder="Name"
                    style={{ border: "1px solid #999999" }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 20 }} lg={{ span: 8 }}>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                  {
                      type: "email",
                      message: "The input is not valid E-mail!"
                  },
                  {
                      required: true,
                      message: "Please input your E-mail!"
                  }
                  ]
                })(
                  <Input
                    placeholder="Email"
                    style={{
                      border: "1px solid #999999"
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 20 }} lg={{ span: 8 }}>
              <Form.Item>
                {getFieldDecorator("phone", {
                  rules: [
                  {
                      required: true,
                      message: "Please input your phone number!",
                      pattern: /\D*([+][9][1]|[9][1]|[0]){0,1}([6-9]{1})([0-9]{9})\D*/

                  }
                  ]
                })(
                  <Input
                    placeholder="Phone"
                    style={{ width: "100%", border: "1px solid #999999" }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={{ span: 20 }} lg={{ span: 12 }}>
              <Form.Item>
                {getFieldDecorator("canContact", {
                  valuePropName: "checked",
                  initialValue: true
                })(<Checkbox>SlashDr personnel can contact me</Checkbox>)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col
              xs={{ span: 20 }}
              lg={{
                span: 8
              }}
            >
              <Form.Item>
                <Button
                  htmlType="submit"
                  size="large"
                  style={{
                    backgroundColor: "#00B466",
                    color: "#fff",
                    border: "0px",
                    padding: "0rem 3rem"
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export const WrappedContactForm = Form.create({ name: "contact" })(ContactForm);
