// import Illustration from "./illustration.png";
import Illustration1A from './images/1A.png'
import Illustration1B from './images/1B.png'
import Illustration1C from './images/1C.png'
import Illustration2A from './images/2A.png'
import Illustration2B from './images/2B.png'
import Illustration2C from './images/2C.png'
import Gokul from './images/Gokul.jpg'
import Sonaal from './images/Sonaal.jpeg'
import Rajesh from './images/Rajesh.png'
import Nandu from './images/nandu.png'
import Mathan from './images/Mathan.jpeg'
import Vijay from './images/Vijay.jpg'
import Rajaraman from "./images/Rajaraman.jpeg";
export const content = {
         hero: {
           title: {
             text: "EHR. For doctors. By doctors.",
             level: 1,
             style: {}
           },
           subtitle: {
             text:
               "SlashDr is an app-first primary care EHR for doctors, conceptualised by doctors and built by technologists. ",
             level: 4,
             style: {}
           },
           thirdlevel: "",
           watch: [
             {
               name: "Watch how complaints work",
               videoId: 123
             },
             {
               name: "Watch how vitals work",
               videoId: 222
             },
             {
               name: "Watch how prescriptions work",
               videoId: 1233
             }
           ],
           heroImgStyle: {
             width: "50%",
             marginTop: "2rem"
           },
           ctaStyle: {
             backgroundColor: "#00B466",
             color: "#fff",
             marginTop: "1.875rem",
             border: "0px"
           },
           sectionStyle: {
             backgroundColor: "#F4F8FF",
             marginTop: "7.8125rem",
             marginBottom: "5.625rem"
           }
         },
         problems: {
           title: {
             text: "Existing problems in healthcare delivery",
             level: 2,
             style: {
               fontSize: "24px",
               fontWeight: "800"
             }
           },
           subtitle: {
             text: "SlashDr is built to address exactly this",
             level: 4,
             style: {
               color: "#018FFF",
               marginTop: "0rem",
               fontSize: "24px",
               fontWeight: "bold"
             }
           },
           thirdlevel:
             "Primary and secondary care forms the foundation of healthcare delivery. Yet, health-tech often fails physicians in this segment. Without assistants and a team of staff, technology becomes an additional burden to them. ",
           statements: [
             {
               question: "What is the cause of the problem?",
               title: "Healthcare can’t be driven by workflows",
               description:
                 "The complexities and chaos of primary and secondary healthcare delivery are not addressed by today’s EHRs, which are built by technologists in silos, with little understanding of clinical problems.",
               image: Illustration1A,
               alignType: "img-txt"
             },
             {
               question: "Why can’t they fix it themselves?",
               title:
                 "Technologists are ill-equipped to solve clinical problems",
               description:
                 "Practising doctors should drive the technology solutions for their problems, investing first-hand in what shapes their healthcare delivery.",
               image: Illustration1B,
               alignType: "txt-img"
             },
             {
               question: "What's the cost of not fixing it?",
               title: "The vast majority remains under-served",
               description:
                 "80% of healthcare is delivered in primary and secondary care levels. Yet, they are the least impacted by today’s health-tech, leading to lack of data and insight from the ground. ",
               image: Illustration1C,
               alignType: "img-txt"
             }
           ],
           sectionStyle: {
             backgroundColor: "#fff",
             paddingTop: "5.625rem",
             paddingBottom: "3.5rem"
           }
         },
         fix: {
           title: {
             text: "EHR for the under-served",
             level: 2,
             style: {}
           },
           subtitle: {
             text: "",
             level: 3,
             style: {
               color: "#018FFF",
               marginTop: "0rem"
             }
           },
           thirdlevel:
             "SlashDr is an ERP-class configurable solution, implemented as an iPad-app, built keeping the primary and secondary care providers in mind.",
           statements: [
             {
               question: "How does the solution work?",
               title: "SlashDr isn’t fixated on a workflow",
               description:
                 "Doctors don’t like an app telling them how to do their work. So, SlashDr doesn’t. It embraces the chaos that defines your space, carefully configured to meet your specific needs, putting you in control.",
               image: Illustration2A,
               alignType: "txt-img"
             },
             {
               question: "Why is it better/unique?",
               title: "SlashDr is co-implemented and continuously supported",
               description:
                 "When you get SlashDr, you get a complete EHR solution: A world-class application personalised for you plus a stellar support team, who in addition to 8x5 support, spend time with you on-site every month, to understand and adapt for you.",
               image: Illustration2B,
               alignType: "img-txt"
             },
             {
               question: "How easily can you switch?",
               title: "SlashDr is quick and easy",
               description:
                 "With SlashDr, you can get rapid implementation in 2-3 weeks. And have incremental updates for your clinical practice over a longer period of time.",
               image: Illustration2C,
               alignType: "txt-img"
             }
           ],
           sectionStyle: {
             backgroundColor: "#018FFF",
             paddingTop: "5.625rem",
             paddingBottom: "3.5rem"
           },
           ctaStyle: {
             backgroundColor: "#fff",
             color: "#018FFF",
             marginTop: "5.625rem",
             borderRadius: "0.3125rem",
             border: "0px"
           }
         },
         testimonial: {
           title: {
             text: "Our users are delighted about using SlashDr",
             level: 2,
             style: {}
           },
           subtitle: {
             text: "",
             level: 3,
             style: {}
           },
           thirdlevel: "",
           statements: [
             // {
             //   name: "Dr Nandagopal",
             //   avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
             //   designation: "Pulmonologist, Coimbatore",
             //   complement: "(addresses chaos in clinics)",
             //   description:
             //     "SlashDr helps manage the chaos in my clinic like a charm. Unlike other tech solutions I’ve tried in the past, SlashDr doesn’t come in the way of my work. It truly helps me do my job better! "
             // },
             {
               name: "Dr Sivaraja",
               avatar:
                 "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
               designation: "Pulmonologist, Thanjavur",
               complement: "(Quick turnaround time)",
               description:
                 "The team at SlashDr set me up with my customised new EHR in two weeks flat, and with very minimal input from me."
             },
             {
               name: "Dr Saravanan",
               avatar:
                 "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
               designation: "Paediatrician & Chest Physician, Tirunelveli",
               complement: "(Highly malleable)",
               description:
                 "All EMRs I’ve seen are strict and inflexible. They didn’t understand that not all doctors do the same thing. But, SlashDr was configured especially for my needs and my way of working. It’s incredible how efficient it is now!"
             },
             {
               name: "Dr Mithiran",
               avatar:
                 "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
               designation: "Ophthalmologist, Chengalpet",
               complement: "(Modern & chic)",
               description:
                 "The app is so modern and chic that using it is a pleasure. With regular updates, it remains up-to-date without me having to do anything."
             },
             {
               name: "Dr Karthik Prabhu",
               avatar:
                 "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
               designation: "Cardiologist, Coimbatore ",
               complement: "(I type a lot less)",
               description:
                 "My biggest grouse with EHRs was that I need to type in so much into a computer. I don’t have an assistant and it was a waste of my time that is better served with patients. With SlashDr, I type much less and care for my patients that much more."
             },
             {
               name: "Dr Sangeetha Meena",
               avatar:
                 "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
               designation: "General Medicine, Chengalpet",
               complement: "(Helps better practice)",
               description:
                 "With the ability to maintain my patients records safely, recollect when necessary, and do it so comfortably on an iPad, my practice has gotten better. "
             }
           ],
           sectionStyle: {
             backgroundColor: "#fff",
             paddingTop: "5.625rem"
           },
           ctaStyle: {
             backgroundColor: "#fff",
             color: "#018FFF",
             marginTop: "5.625rem",
             borderRadius: "0.3125rem",
             border: "0px"
           }
         },
         contact: {
           title: {
             text: "Do you have more questions?",
             level: 2,
             style: {}
           },
           subtitle: {
             text:
               "We’ll show you around the product, give you an experience of what it’s like to use SlashDr and answer any questions you may have. ",
             level: 3,
             style: {
               marginTop: "0rem",
               fontSize: "1.125rem",
               marginBottom: "1.5rem"
             }
           },
           thirdlevel: "We are available Mon-Sat 9 AM to 9 PM",
           statements: [
             {
               text: "+91 98403 20976",
               icon: "phone"
             },
             {
               text: "gokul@slashdr.com",
               icon: "mail"
             }
           ],
           sectionStyle: {
             backgroundColor: "#fff",
             marginTop: "5.625rem",
             paddingTop: "5.625rem",
             paddingBottom: "5.625rem"
           },
           iconStyle: {
             marginRight: "0.5rem",
             background: "#018FFF",
             color: "white",
             borderRadius: "50%",
             padding: "6px"
             // transform: "rotate(90deg)"
           },
           ctaStyle: {
             backgroundColor: "#fff",
             color: "#018FFF",
             marginTop: "5.625rem",
             borderRadius: "0.3125rem",
             border: "0px"
           }
         },
         about: {
           sectionStyle: {
             backgroundColor: "#F4F8FF",
             marginTop: "7.8125rem",
             marginBottom: "7.8125rem"
           },
           newSection: {
             text:
               "Four years ago, Dr Nandagopal and Dr Vijay, called Gokul to build a “google form”. The moment Gokul heard what they asked for, he realised that there is a problem with the narrative. And that we may be staring at a much bigger problem. All of them ventured to clearly define the problem; then Gokul spent about six months touring Tamil Nadu/Kerala, sitting in clinics and understanding the goings on. They came back together to discuss the findings and then it dawned on them.",
             style: {
               fontWeight: "600",
               fontSize: "20px",
               lineHeight: "160%"
             }
           },
           sections: [
             {
               highlighted:
                 "Primary healthcare problem is posing as a “workflow” problem in clinics",
               explainer:
                 "70% of healthcare in India happens at a primary care level (read: OP clinics, standalone and part of hospitals). Yet, there are absolutely no systems at that level. Workflow is chaotic, patients come and go at various points without any documentation. This was appalling!",
               highlightedStyle: {
                 color: "#000",
                 fontSize: "1.5rem",
                 lineHeight: "1.8125rem",
                 textAlign: "left",
                 fontWeight: "bold"
               },
               explainerStyle: {
                 color: "#2b2b2b",
                 fontSize: "1rem",
                 lineHeight: "1.625rem",
                 textAlign: "left"
               },
               type: "highlightFirst"
             },
             {
               highlighted:
                 "What would happen if we ignore primary care, and build only for tertiary/quaternary care?",
               explainer:
                 "Early detection and wellness for the whole country would suffer. Simply because the earliest of the symptoms occur at the primary stage and the problem is best identified then. Without a robust EHR system for the primary and secondary care segments, there is no data and insight from the ground.",
               highlightedStyle: {
                 color: "#000",
                 fontSize: "1.5rem",
                 lineHeight: "1.8125rem",
                 textAlign: "left",
                 fontWeight: "bold"
               },
               explainerStyle: {
                 color: "#2b2b2b",
                 fontSize: "1rem",
                 lineHeight: "1.625rem",
                 textAlign: "left"
               },
               type: "textFirst"
             },
             {
               highlighted: "In this vacuum, we saw opportunity",
               explainer:
                 "The more we understood how this segment works, the more we saw that the space was open for disruption. We knew that regulation will also soon catch up and doctors will start looking for help.",
               highlightedStyle: {
                 color: "#000",
                 fontSize: "1.5rem",
                 lineHeight: "1.8125rem",
                 textAlign: "left",
                 fontWeight: "bold"
               },
               explainerStyle: {
                 color: "#2b2b2b",
                 fontSize: "1rem",
                 lineHeight: "1.625rem",
                 textAlign: "left"
               },
               type: "highlightFirst"
             },
             {
               highlighted: "With opportunity came the challenges",
               explainer:
                 "Doctors got no time for you! Primary and secondary care physicians are the most overworked in the country. Spending time with them and understanding their needs is next to impossible. But more importantly, doctors see documenting as giving up their ‘secret sauce’ and ceding control. To convince them of the benefits of using a product like this, emotional appeal is just as important as the strengths of the product itself.",
               highlightedStyle: {
                 color: "#000",
                 fontSize: "1.5rem",
                 lineHeight: "1.8125rem",
                 textAlign: "left",
                 fontWeight: "bold"
               },
               explainerStyle: {
                 color: "#2b2b2b",
                 fontSize: "1rem",
                 lineHeight: "1.625rem",
                 textAlign: "left"
               },
               type: "textFirst"
             }
           ],
           bottomliner: {
             text:
               "Of course, the Clinical Establishment Act also helped. The hard deadline is 31st of May 2019, are you compliant yet?",
             style: {
               color: "#2F2F2F",
               fontSize: "1.25rem",
               textAlign: "center",
               fontWeight: "bold"
             }
           },
           team: [
             {
               name: "Dr Nandagopal V",
               avatar: Nandu,
               designation: "Chairman & MD"
             },
             {
               name: "Gokul Visweswaran",
               avatar: Gokul,
               designation: "CEO"
             },
             {
               name: "Dr Vijay S",
               avatar: Vijay,
               designation: "CQO"
             },
             {
               name: "Dr Mathan K",
               avatar: Mathan,
               designation: "Chief of Research and Analytics"
             },
             {
               name: "Rajesh Padmanabhan",
               avatar: Rajesh,
               designation: "COO"
             },
             {
               name: "Sonaal Bangera",
               avatar: Sonaal,
               designation: "CDO"
             },
             {
               name: "Rajaraman Srinivasan",
               avatar: Rajaraman,
               designation: "VP - Sales & CTO"
             }
             // {
             //   name: 'Ravi Kumar',
             //   avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
             //   designation: 'Implementation'
             // },
             // {
             //   name: 'Keerthana E',
             //   avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
             //   designation: 'Quality'
             // },
             // {
             //   name: 'Ranjith K',
             //   avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
             //   designation: 'Database & Controllers'
             // },
             // {
             //   name: 'Anas P',
             //   avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
             //   designation: 'iOS Development'
             // },
             // {
             //   name: 'Hema Chandru',
             //   avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
             //   designation: 'Android Development'
             // },
             // {
             //   name: 'Shankar S',
             //   avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
             //   designation: 'Web Development'
             // },
           ]
         }
       };
