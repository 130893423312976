import React, { Component } from "react";
import { Typography, Divider, Row, Col, Button, Icon } from "antd";
import Illustration from "../illustration.png";
import { content } from "../content";
export default class TheFix extends Component {
  render() {
    const { fix } = content;
    return (
      <div id="fix" style={fix.sectionStyle}>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Typography.Title
              level={2}
              style={{
                color: "#fff"
              }}
            >
              {fix.title.text}
            </Typography.Title>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Typography.Title
              level={3}
              style={{
                color: "#fff"
              }}
            >
              {fix.subtitle.text}
            </Typography.Title>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 10 }}>
            <Typography.Paragraph
              style={{
                color: "#fff"
              }}
            >
              {fix.thirdlevel}
            </Typography.Paragraph>
          </Col>
        </Row>

        {fix.statements.map((prob, index) => {
          return (
            <React.Fragment key={index}>
              <Row
                gutter={16}
                type="flex"
                align="middle"
                style={{
                  paddingTop: "5.625rem"
                }}
              >
                <Col
                  xs={{ span: 24 }}
                  lg={{
                    span: 8,
                    offset: 2,
                    order: prob.alignType === "img-txt" ? 1 : 2
                  }}
                >
                  <img src={prob.image} className="thefix--images" />
                </Col>
                <Col
                  className="thefix--text"
                  xs={{ span: 24 }}
                  lg={{
                    span: 8,
                    offset: 2,
                    order: prob.alignType === "img-txt" ? 2 : 1
                  }}
                  // style={{
                  //   textAlign: "left",
                  //   color: "#fff"
                  // }}
                >
                  {/* <Typography.Text
                    type="secondary"
                    style={{
                      color: "#D8F8EA"
                    }}
                    >
                    {prob.question}
                  </Typography.Text> */}
                  <Typography.Title
                    level={4}
                    style={{
                      marginTop: "0.8125rem",
                      color: "#fff",
                      fontSize: '1.5rem',
                      fontWeight: "800"
                    }}
                  >
                    {prob.title}
                  </Typography.Title>
                  <Typography.Paragraph
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#D8F8EA"
                    }}
                  >
                    {prob.description}
                  </Typography.Paragraph>
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
        <Row justify="center">
          <Button size="large" style={fix.ctaStyle} href="/#contact">
            Contact Us
          </Button>
        </Row>
      </div>
    );
  }
}
