import React, { Component } from "react";
import { Layout, Menu, Row, Col, Avatar, Typography, Button } from "antd";
import Navigation from "./sections/navigation";
import { content } from "./content";
import FooterSection from "./sections/footer";
const { Header, Footer, Sider, Content } = Layout;

export default class AboutUs extends Component {
  render() {
    const { about } = content;
    return (
      <React.Fragment>
        <Layout
          style={{
            backgroundColor: "#F4F8FF"
          }}
        >
          <Header
            style={{
              backgroundColor: "#F4F8FF",

              position: "fixed",
              zIndex: 1,
              width: "100%"
            }}
          >
            <Navigation {...this.props} />
          </Header>
          <Content>
            <div id="about">
              <Row
                type="flex"
                gutter={16}
                style={{
                  marginTop: "5.625rem"
                }}
                justify="center"
              >
                <Col xs={{ span: 18 }} lg={{ span: 12 }}>
                  <p style={about.newSection.style} className="firstLetter">
                    {about.newSection.text}
                  </p>
                </Col>
              </Row>
            </div>
            <div style={about.sectionStyle} className="about--section">
              {about.sections.map((sec, index) => {
                return (
                  <Row
                    type="flex"
                    gutter={16}
                    key={index}
                    sth={sec.type}
                    style={{
                      marginBottom: "5.625rem"
                    }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      lg={{
                        span: 12,
                        order: sec.type === "highlightFirst" ? 1 : 2
                      }}
                    >
                      <p className="highlighted" style={sec.highlightedStyle}>
                        {sec.highlighted}
                      </p>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      lg={{
                        span: 12,
                        order: sec.type === "highlightFirst" ? 2 : 1
                      }}
                    >
                      <p style={sec.explainerStyle}>{sec.explainer}</p>
                    </Col>
                  </Row>
                );
              })}
              <p id="team" style={about.bottomliner.style}>
                {about.bottomliner.text}
              </p>
              <Button
                size="large"
                style={{
                  backgroundColor: "#00B466",
                  color: "#fff",
                  // marginTop: "1.875rem",
                  border: "0px"
                }}
                href="/#contact"
              >
                Get SlashDr
              </Button>
            </div>
            <Row
              gutter={16}
              style={{
                marginTop:'2.8125rem'
              }}
              // id="teamSection"
              justify="center"
            >
              <Col span={24}>
                <Typography.Title level={1}>Our Team</Typography.Title>
              </Col>
            </Row>
            <Row
              gutter={16}
              className="team--members"
              style={{
                backgroundColor: "#F4F8FF",
                marginTop: "2.8125rem",
                marginBottom: "7.8125rem"
              }}
              id="teamSection"
            >
              {about.team.map((member, index) => {
                return (
                  <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Avatar
                      size={110}
                      src={member.avatar}
                      style={{ marginBottom: "0.875rem" }}
                    />
                    <Typography.Paragraph level={4}>
                      {member.name}
                    </Typography.Paragraph>
                    <Typography.Paragraph type="secondary" level={4}>
                      {member.designation}
                    </Typography.Paragraph>
                  </Col>
                );
              })}
            </Row>
          </Content>
          <Footer className="custom--footer">
            <FooterSection {...this.props} />
          </Footer>
        </Layout>
      </React.Fragment>
    );
  }
}
