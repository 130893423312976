import React, { Component } from "react";
import { Carousel, Card, Avatar, Row, Col, Typography } from "antd";
import { content } from '../content'

export default class Testimonials extends Component {
  render() {
    const { testimonial } = content
    return (
      <div id="testimonials">
        <Row type="flex" justify="center" style={testimonial.sectionStyle}>
          <Col span={24}>
            <Typography.Title level={testimonial.title.level} strong>
              {testimonial.title.text}
            </Typography.Title>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Carousel effect="fade" autoplay arrows>
              {testimonial.statements.map((testimonial, index) => {
                return (
                  <div key={index}>
                    <Card bordered={false} style={{height: '350px'}}>
                      <Card bordered={false} className="carousel--custom">
                        <Card.Meta
                          title={
                            <div
                              style={{
                                textAlign: "left"
                              }}
                            >
                              <span>{testimonial.name}</span> <br />
                              <span style={{fontSize: '0.875rem', color:'#999' }}>{testimonial.designation}</span>
                            </div>
                          }
                        />
                        {/* <Card.Meta
                          avatar={<Avatar src={testimonial.avatar} style={{width: '3rem', height: '3rem'}}/>}
                          title={
                            <div
                          style={{
                          textAlign: "left"
                          }}
                            >
                          <span>{testimonial.name}</span> <br />
                          <span style={{fontSize: '0.875rem', color:'#999' }}>{testimonial.designation}</span>
                            </div>
                          }
                        /> */}
                        <p
                          style={{
                            textAlign: "left",
                            marginTop: "1rem"
                          }}
                        >
                          {testimonial.description}
                        </p>
                      </Card>
                    </Card>
                  </div>
                );
              })}
            </Carousel>
          </Col>
        </Row>
      </div>
    );
  }
}
