import React, { Fragment, Component, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Landing from './landing'
import AboutUs from './about'
export default class MyComponent extends Component {
  render() {
    return (
      <main>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/about" component={AboutUs} />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }
}
