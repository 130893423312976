import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Icon,
  Divider,
  Input,
  Checkbox
} from "antd";
import { content } from "../content";
import { WrappedContactForm } from "./contactForm";
export default class Contact extends Component {
  render() {
    const { contact } = content;
    return (
      <div id="contact" style={contact.sectionStyle}>
        <Row type="flex" justify="center">
          <Col
            xs={{ span: 20 }}
            lg={{
              span: 12
            }}
          >
            <Typography.Title level={contact.title.level}>
              {contact.title.text}
            </Typography.Title>
            <Typography.Title
              level={contact.subtitle.level}
              type="secondary"
              style={contact.subtitle.style}
            >
              {contact.subtitle.text}
            </Typography.Title>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          {contact.statements.map((item, index) => {
            return (
              <Col
                xs={{ span: 24 }}
                lg={{
                  span: 4
                }}
                className="contactDeets"
                key={index}
              >
                <Icon type={item.icon} style={contact.iconStyle} />
                <span>{item.text}</span>
              </Col>
            );
          })}
        </Row>
        <Row type="flex" justify="center">
          <Col
            xs={{ span: 18 }}
            lg={{
              span: 8
            }}
          >
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col
            xs={{ span: 24 }}
            lg={{
              span: 12
            }}
          >
            <Typography.Title
              level={4}
              type="secondary"
              style={{
                marginTop: "0rem",
                fontSize: "1.125rem"
              }}
            >
              {contact.thirdlevel}
            </Typography.Title>
          </Col>
        </Row>
        <Row
          gutter={16}
          type="flex"
          justify="center"
          style={{
            marginTop: "1.75rem"
          }}
        >
          {/* <Col span={4}>
            <Input
              placeholder="Name"
              style={{
            border: "1px solid #999999"
              }}
            />
            </Col>
            <Col span={4}>
            <Input
              placeholder="Email"
              style={{
            border: "1px solid #999999"
              }}
            />
            </Col>
            <Col span={4}>
            <Input
              placeholder="Phone"
              style={{
            border: "1px solid #999999"
              }}
            />
          </Col> */}
          <Col
            xs={{ span: 24 }}
            lg={{
              span: 12
            }}
          >
            <WrappedContactForm />
          </Col>

        </Row>
        {/* <Row
          gutter={16}
          type="flex"
          justify="center"
          style={{
            marginTop: "1.875rem"
          }}
          >
          <Col
            xs={{ span: 12 }}
            lg={{
          span: 6
            }}
          >
            <Checkbox>Agents at SlashDr can contact me</Checkbox>
          </Col>
        </Row> */}

        {/* <Row gutter={16} type="flex" justify="center">
          <Col
            xs={{ span: 24 }}
            lg={{
          span: 6
            }}
          >
            <Button
          style={{
          backgroundColor: "#00B466",
          color: "#fff",
          marginTop: "1.875rem",
          border: "0px",
          padding: "0rem 3rem"
          }}
            >
          Submit
            </Button>
          </Col>
        </Row> */}
      </div>
    );
  }
}
