import React, { Component } from "react";
import { Layout, Menu, Row, Col } from "antd";
import drLogo from "../dr_logo.png";
import { HashLink as Link } from "react-router-hash-link";

const { Header, Footer, Sider, Content } = Layout;
const navItems = [
  {
    key: "1",
    title: "Existing Problems",
    link: "#problems"
  },
  {
    key: "2",
    title: "How we fix",
    link: "#fix"
  },
  {
    key: "3",
    title: "What Doctors say",
    link: "#testimonials"
  },
  {
    key: "4",
    title: "Pricing",
    link: "#pricing"
  },
  {
    key: "5",
    title: "About",
    link: "/about"
  },
  {
    key: "6",
    title: "Contact Us",
    link: "#contact"
  }
];
export default class Navigation extends Component {
  handleMenuClick = e => {
    console.log(e);
  };
  render() {
    return (
      <div>
        {/* <Row type="flex" justify="space-between"> */}
        {/* <Col>
            <div className="logo">
            <img src={drLogo} width="50%" />
            </div>
        </Col> */}
        {/* <Col>

        </Col> */}
        {/* </Row> */}
        <Menu
          theme="light"
          mode="horizontal"
          style={{ lineHeight: "64px", backgroundColor: "#F4F8FF" }}
          onClick={this.handleMenuClick}
        >
          <Menu.Item key="logo" className="logo">
            <Link to="/">
              <img src={drLogo} width="75%" />
            </Link>
          </Menu.Item>
          {navItems.map((item, index) => {
            return (
              <Menu.Item key={item.key}>
                <Link
                  to={
                    this.props.location.pathname === "/"
                      ? `${item.link}`
                      : `/${item.link}`
                  }
                >
                  {item.title}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  }
}
