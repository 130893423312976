import React, { Component } from "react";
import { Typography, Divider, Row, Col, Button, Icon } from "antd";
import Illustration from "../illustration.png";
import { content } from "../content";

export default class Problems extends Component {
  render() {
    const { problems } = content;
    return (
      <div id="problems" style={problems.sectionStyle}>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Typography.Title
              level={problems.title.level}
              style={problems.title.style}
              strong
            >
              {problems.title.text}
            </Typography.Title>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 10 }}>
            <Typography.Paragraph type="secondary">
              {problems.thirdlevel}
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={{ span: 18 }} lg={{ span: 10 }}>
            <Divider />
            <Typography.Paragraph style={problems.subtitle.style}>
              {problems.subtitle.text}
            </Typography.Paragraph>
          </Col>
        </Row>

        {problems.statements.map((prob, index) => {
          return (
            <React.Fragment key={index}>
              <Row
                gutter={16}
                type="flex"
                align="middle"
                style={{
                  marginTop: "5.625rem"
                }}
              >
                <Col
                  xs={{ span: 24 }}
                  lg={{
                    span: 8,
                    offset: 2,
                    order: prob.alignType === "img-txt" ? 1 : 2
                  }}
                >
                  <img src={prob.image} className="problem--images" />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  lg={{
                    span: 8,
                    offset: 2,
                    order: prob.alignType === "img-txt" ? 2 : 1
                  }}
                  className="problem--text"
                >
                  {/* <Typography.Text type="secondary">
                    {prob.question}
                  </Typography.Text> */}
                  <Typography.Title
                    level={4}
                    style={{
                      color: "#018FFF",
                      marginTop: "0.625rem",
                      fontWeight: "800",
                      fontSize: '1.5rem'
                    }}
                    strong
                  >
                    {prob.title}
                  </Typography.Title>
                  <Typography.Paragraph style={{fontWeight: '600', fontSize: '16px', color: '#2f2f2f'}}>
                    {prob.description}
                  </Typography.Paragraph>
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
